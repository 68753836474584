export function setVH() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty(`--vh`, `${vh}px`);
}

window.addEventListener(`resize`, setVH);

export function debounce(callback, time = 250, interval) {
	return (...args) => {
		clearTimeout(interval);
		interval = setTimeout(() => callback(...args), time);
	}
}
