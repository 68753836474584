/*____________________ Server ____________________*/
// export const URL_BASE = '/';
// export const URL_IMG = '/';


/*____________________ Test Global ____________________*/
// export const URL_BASE = 'http://merchant.qrpay.uz/';
// export const URL_IMG = 'http://merchant.qrpay.uz/';

/*____________________ Test Local ____________________*/
export const URL_BASE = 'http://172.17.12.36:3513/';
export const URL_IMG = 'http://172.17.12.36:3513/';


export const LANG = 'en';



export default {
	URL_BASE,
	URL_IMG,
	LANG,
};
