import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ru from '@/locales/ru.json';
import uz from '@/locales/uz.json';
import en from '@/locales/en.json';

Vue.use(VueI18n)

const locale = localStorage.getItem('lang') || 'uz';

const i18n = new VueI18n({
  locale,
  fallbackLocale: locale,
  messages: { en, ru, uz }
})

export const changeLang = locale => {
  i18n.locale = locale;
  localStorage.setItem('lang', locale)
};

export const getLocale = () => {
  return i18n.locale;
};

export default i18n
