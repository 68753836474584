import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc, getDocs, getDoc, doc, query, where, updateDoc, setDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyDI0vbQwkPGGPuyF371P2KQ1nTb-4nrAVw",
  authDomain: "orzutravel-37ea8.firebaseapp.com",
  projectId: "orzutravel-37ea8",
  storageBucket: "orzutravel-37ea8.appspot.com",
  messagingSenderId: "1020353644430",
  appId: "1:1020353644430:web:fd72b0f557331ebc24c6df"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export function addItem(table, data){
  return new Promise((resolve, reject) => {
    addDoc(collection(db, String(table)), data)
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}

export function getItems(table){
  return new Promise((resolve, reject) => {
    getDocs(collection(db, String(table)))
      .then(data => {
        let arrayList =[];
        data.forEach(item => {
          arrayList.push({ id: item.id, ...item.data() });
          resolve(arrayList);
        })
      })
      .catch(err => {
        reject(err)
      })
  })
}


