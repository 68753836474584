import Vue from 'vue';
import Axios from 'axios';
import router from '../router';
import store from '../store';
import notification from './vue-notification';
import { removeAllTokens, token } from '@/services/TokenService';
import { LANG, URL_BASE } from '@/defaults';



const instance = Axios.create({
	baseURL: URL_BASE,
	headers: {
		common: {
			'X-Requested-With': 'XMLHttpRequest',
		},
	},
});



instance.interceptors.request.use(config => {

	config.headers['Accept-Language'] = localStorage.getItem('lang') ? localStorage.getItem('lang') : LANG;

	if (token.get())
	{
		config.headers.Authorization = 'Bearer ' + token.get();
	}


	return config;
}, error => Promise.reject(error));



instance.interceptors.response.use(response => response, error => {

	if (error.response.status === 401)
	{
		removeAllTokens();
		store.state.userProfile = {};
		store.state.permissionList = [];
		router.push({ name: 'auth' });
	}

	notification.error(error.response.data.error.message);


	return Promise.reject(error);
});



Vue.prototype.$http = instance;
