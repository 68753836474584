import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
// import vuetify from './plugins/vuetify';
import './plugins/index';
import './mixins/index';
import './directives/index';
import './filters';
import '@mdi/font/css/materialdesignicons.css';
import './assets/css/main.css';
import "@/plugins/firebase"
import i18n from './i18n'


Vue.config.productionTip = false;


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app');
