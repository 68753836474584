<template>
		<router-view/>


<!--		<notifications group="simple" position="top right" :duration="5000"></notifications>-->
</template>

<script>
import { setVH } from '@/utils/frontend';
import TokenService from '@/services/TokenService';
import { v4 as generateUniqueId } from 'uuid';

export default {
	name: 'App',
	// watch: {
	// 	$route: {
	// 		handler(to, from)
	// 		{
	// 			document.title = to.meta.title ? to.meta.title() : 'QRPay Bank';
	// 		},
	// 		immediate: true,
	// 	},
	// },
	// created()
	// {
	// 	if (TokenService.token.get())
	// 	{
	// 		this.$store.state.refreshTokenIntervalId = setInterval(() => {
	// 			if (+new Date() / 1000 > TokenService.expireTime.get() - 60)
	// 			{
	// 				this.refreshToken();
	// 			}
	// 		}, 1000);
	//
	//
	// 		this.getProfileInfo();
	// 	}
	//
	//
	// 	if (!TokenService.deviceId.get())
	// 	{
	// 		TokenService.deviceId.save(generateUniqueId());
	// 	}
	// },
	mounted()
	{
		setVH();
	},
	// methods: {
	// 	refreshToken()
	// 	{
	// 		this.$http
	// 			.post('api-merchant/Auth/RefreshToken', {
	// 				accessToken: TokenService.token.get(),
	// 				refreshToken: TokenService.refreshToken.get(),
	// 			})
	// 			.then(response => {
	// 				TokenService.token.save(response.data.result.token);
	// 				TokenService.refreshToken.save(response.data.result.refreshToken);
	// 				TokenService.expireTime.save((+new Date() / 1000) + response.data.result.expireSeconds);
	// 			});
	// 	},
	// 	getProfileInfo()
	// 	{
	// 		this.$http
	// 			.get('api-merchant/Auth/Profile')
	// 			.then(response => {
	// 				this.$store.state.userProfile = response.data.result;
	// 				this.$store.state.permissionList = response.data.result.permissions;
	// 			});
	// 	},
	// },
};
</script>
