import Vue from 'vue';
import VueRouter from 'vue-router';
import { token } from '@/services/TokenService';


Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name: 'main',
		component: () => import('@/layout/MainLayout'),
		redirect: { name: 'Home' },
		children: [
			{
				path: 'home',
				name: 'Home',
				component: () => import('@/views/Main/Home'),
			},
			{
				path: 'about',
				name: 'About',
				component: () => import('@/views/Main/About'),
			},
			{
				path: 'services',
				name: 'Services',
				component: () => import('@/views/Main/Services'),
			},
			{
				path: 'packages',
				name: 'Packages',
				component: () => import('@/views/Main/Packages'),
			},
			{
				path: 'contact',
				name: 'Contact',
				component: () => import('@/views/Main/Contact'),
			},
			{
				path: 'payment/:id',
				name: 'Payment',
				component: () => import('@/views/Main/Payment'),
			},
		],
	},

	// {
	// 	path: '/auth',
	// 	name: 'auth',
	// 	component: () => import('@/layout/AuthorizationLayout'),
	// 	redirect: { name: 'authLogin' },
	// 	meta: {
	// 		public: true,
	// 	},
	// 	children: [
	// 		{
	// 			path: 'login',
	// 			name: 'authLogin',
	// 			component: () => import('@/views/Authorization/Login/index'),
	// 		},
	// 		{
	// 			path: 'registration',
	// 			name: 'authRegistration',
	// 			component: () => import('@/views/Authorization/Registration/index'),
	// 		},
	// 	],
	// },
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior: () => ({
		y: 0,
	}),
});

// router.beforeEach((to, from, next) => {
//
// 	let hasToken = !!token.get();
// 	let isPublic = to.matched.some((record) => record.meta.public);
//
// 	if (hasToken && isPublic)
// 	{
// 		return next(from.fullPath);
// 	}
//
// 	if (!hasToken && !isPublic)
// 	{
// 		return next({ name: 'auth' });
// 	}
//
// 	next();
// });

export default router;
