import Vue from 'vue';



Vue.directive('number', {
	update(el, binding, vnode, oldVnode)
	{
		let elem = el;

		if (binding.modifiers.find)
		{
			elem = el.querySelector('input');
		}

		if (elem.value)
		{
			elem.value = elem.value.replace(/[^0-9]/g, '');
			elem.dispatchEvent(new Event('input'));
		}
	},
});