import Vue from 'vue';
import Vuex from 'vuex';


Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		FORM_TYPE: {
			CREATE: 'Create',
			UPDATE: 'Update',
			READ: 'Read',
		},
		refreshTokenIntervalId: 0,
		userProfile: {},
		permissionList: [],
		tourList: [
			{
				id: 1,
				name_uz: "Ipak yo'li kichik guruh eskort sayohatlari 2023-2024",
				name_ru: "Небольшие групповые туры по Шелковому пути 2023-2024 гг.",
				name_en: "Silk Road Small Group Escorted Tours 2023-2024",
				destination_uz: "O'zbekiston",
				destination_ru: "Узбекистан",
				destination_en: "Uzbekistan",
				duration_uz: "7 kun",
				duration_ru: "7 дней",
				duration_en: "7 days",
				num_of_person_uz: "7-14 kishi",
				num_of_person_ru: "7-14 человек",
				num_of_person_en: "7-14 people",
				star: "4.5",
				price_us: "$350",
				price: 3990000,
				image: 'image/bukhara.jpg'
			},
			{
				id: 2,
				name_uz: "Dubayga sayohat",
				name_ru: "Поездка в Дубай",
				name_en: "A trip to Dubai",
				destination_uz: "BAA",
				destination_ru: "ОАЭ",
				destination_en: "UAE",
				duration_uz: "10 kun",
				duration_ru: "10 дней",
				duration_en: "10 days",
				num_of_person_uz: "7-14 kishi",
				num_of_person_ru: "7-14 человек",
				num_of_person_en: "7-14 people",
				star: "4.5",
				price_us: "$305",
				price: 3990000,
				image: 'image/dubai.jpg'
			},
			{
				id: 3,
				name_uz: "Maldiv orollari",
				name_ru: "Мальдивы",
				name_en: "Maldives",
				destination_uz: "Maldiv orollari",
				destination_ru: "Мальдивы",
				destination_en: "Maldives",
				duration_uz: "7 kun",
				duration_ru: "7 дней",
				duration_en: "7 days",
				num_of_person_uz: "8-20 kishi",
				num_of_person_ru: "8-20 человек",
				num_of_person_en: "8-20 people",
				star: "4.6",
				price_us: "$770",
				price: 8778000,
				image: 'image/maldives.jpg'
			}
		]
	},
	getters: {
		tourList: (state) => state.tourList
	},
	mutations: {},
	actions: {},
	modules: {},
});
